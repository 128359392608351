import type { StaticMainConfig } from '../chapito-static'
import type { EventsSortOrder, GuestSortOrder } from '../navigation'
import type { StaticEvent, StaticGroup } from '../types'

const normalizeString = (text: string): string =>
  text
    .toLocaleLowerCase()
    .trim()
    .replace(/["',;:.\-_%!&§@#*<>]/g, '')

export const eventsSortingFunctions = {
  alphabetical: (e1, e2) => normalizeString(e1.title).localeCompare(normalizeString(e2.title)),
  place: (e1, e2) => (!e1.scene ? (!e2.scene ? 0 : -1) : !e2.scene ? +1 : e1.scene.name.localeCompare(e2.scene.name)),
  relevance: (e1, e2) => (e2.weight ?? 0) - (e1.weight ?? 0),
  time: (e1, e2, cfg) =>
    ((cfg.orderEventsByEndDate ? e1.showEndDate : e1.showStartDate) ?? e1.day) -
    ((cfg.orderEventsByEndDate ? e2.showEndDate : e2.showStartDate) ?? e2.day),
} satisfies Record<EventsSortOrder, (e1: StaticEvent, e2: StaticEvent, staticCfg: StaticMainConfig) => number>

export const guestsSortingFunctions: Record<GuestSortOrder, (g1: StaticGroup, g2: StaticGroup) => number> = {
  alphabetical: (g1, g2) => normalizeString(g1.name).localeCompare(normalizeString(g2.name)),
  relevance: (g1, g2) => (g2.weight ?? 0) - (g1.weight ?? 0),
}
