import React from 'react'
import { StyleSheet } from '../stylesheet'

type MapEmbedProps = {
  target: { lng: number; lat: number }
  style?: React.CSSProperties
}

export const MapEmbed: React.FC<MapEmbedProps> = ({ target, style }) => {
  const bbox = [target.lng - 0.001, target.lat - 0.001, target.lng + 0.001, target.lat + 0.001]

  return (
    <a style={style} href={getExternalMapLink(target)} target="_blank" rel="noreferrer">
      <iframe
        style={styles.iframe}
        src={`https://www.openstreetmap.org/export/embed.html?bbox=${bbox.join('%2C')}&layer=mapnik&marker=${target.lat}%2C${target.lng}`}
      />
    </a>
  )
}

export function getExternalMapLink({ lng, lat }: { lng: string | number; lat: string | number }): string {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
}

const styles = StyleSheet.create({
  iframe: {
    border: 0,
    borderRadius: 10,
    overflow: 'hidden',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
  },
})
