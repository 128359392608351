import type { Config } from './grab'
import type { DateDay, DateTimeMs, Festival, GrabEvent } from '../grab/types'

// Dim. 24 avr.
export const formatDayShort = (day: DateTimeMs | DateDay, config: Pick<Config, 'timezone' | 'lang'>): string =>
  new Date(day).toLocaleDateString(config.lang, {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    timeZone: config.timezone,
  })

// Vendredi 23 avril
export const formatDayLong = (day: DateTimeMs | DateDay, config: Pick<Config, 'timezone' | 'lang'>): string =>
  new Date(day).toLocaleDateString(config.lang, {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    timeZone: config.timezone,
  })

// Avril 23
export const formatMonthShort = (day: DateTimeMs | DateDay, config: Pick<Config, 'timezone' | 'lang'>): string => {
  const month = new Date(day).toLocaleDateString(config.lang, {
    month: 'long',
    year: '2-digit',
    timeZone: config.timezone,
  })
  return `${month.slice(0, 1).toUpperCase()}${month.slice(1)}`
}

export const showHour = (
  date: DateTimeMs | undefined,
  festival: Pick<Festival, 'alwaysShowEventHour' | 'showEventHourDelay'>,
  current: DateTimeMs
): boolean => {
  if (!date) {
    return false
  }
  if (!festival.alwaysShowEventHour) {
    const limit = date - (festival.showEventHourDelay ?? 0) * 60 * 1000
    if (current < limit) {
      return false
    }
  }
  return true
}

// 19:00
export const formatHour = (date: DateTimeMs, config: Pick<Config, 'lang' | 'timezone'>): string => {
  return new Date(date).toLocaleTimeString(config.lang, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: config.timezone,
  })
}

// "12:00 > 13:00" or "12:00" if endDate must not be showed
export const formatEndAndStartHour = (
  event: Pick<GrabEvent, 'showStartDate' | 'showEndDate' | 'hideEndDate'>,
  config: Pick<Config, 'lang' | 'timezone'>,
  festival: Pick<Festival, 'eventsHideEndDate'>
): string => {
  const startTime = event.showStartDate ? formatHour(event.showStartDate, config) : ''
  const endTime = event.showEndDate && !event.hideEndDate && !festival.eventsHideEndDate ? ` > ${formatHour(event.showEndDate, config)}` : ''
  return `${startTime}${endTime}`
}

// 2022-12-30
export const getDateDay = (date: DateTimeMs, config: Pick<Config, 'timezone'>): DateDay => {
  // date - 6h
  const d = new Date(date - 21600000)
  // Use the Sweden locale because it uses the ISO format
  return d.toLocaleDateString('sv', { timeZone: config.timezone }) as DateDay
}

export const getMonthDay = (date: DateTimeMs): DateDay => {
  // date - 6h
  const d = new Date(date - 21600000)
  // Use the Sweden locale because it uses the ISO format
  return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-01` as DateDay
}

export const addMinutes = (date: DateTimeMs, minutes: number): DateTimeMs => {
  return (date + minutes * 60 * 1000) as DateTimeMs
}

export const startOfDay = (date: DateTimeMs): DateTimeMs => {
  const startOfDay = new Date(date)
  startOfDay.setUTCHours(0, 0, 0, 0)
  return startOfDay.getTime() as DateTimeMs
}
