import { useTranslation } from 'react-i18next'
import type { DateTimeMs, Genre, TransformedImageId } from '../../../grab/types'
import { Card, HorizontalBar, IconBtn, MapBtn, NavigationLink, RoundIcon, getNavigationPermalink } from './components-lib'
import { MainStylesVars, StyleSheet } from '../stylesheet'
import React, { memo, useContext } from 'react'
import { CalendarButton } from './calendar-button'
import { ResourceImage } from './resource-image'
import type { AppPath } from '../navigation'
import type { StaticEvent } from '../types'
import { MainConfig, useLang } from '../chapito-static'
import { faMapPin, faTicket } from '@fortawesome/free-solid-svg-icons'
import css from './guest-or-event-card.css'
import { formatDayLong, formatEndAndStartHour, formatHour, showHour } from '../../../services/date'

export type GroupOrEvent = {
  image: TransformedImageId | undefined
  genres: Genre[]
  description: string | undefined
} & (
  | {
      name: string
      location: string[] | undefined
    }
  | {
      title: string
    }
)

type GuestOrEventPageProps = {
  groupOrEvent: GroupOrEvent
  event: StaticEvent | undefined
  display: 'normal' | 'large' | 'small' // normal: image+title+1 link btn   large: image+title+btns+map   small: title+btns+map
  style?: React.CSSProperties
  navLink?: AppPath
}

type DateArray = [number, number, number, number, number]

function capitalizeDateString(text: string): string {
  return text
    .split(' ')
    .map((part) => part[0].toUpperCase() + part.substring(1))
    .join(' ')
}

export const GuestOrEventCard: React.FC<GuestOrEventPageProps> = memo(
  function GuestOrEventCard({ groupOrEvent, event, style, display, navLink }) {
    const { t } = useTranslation()
    const lang = useLang()
    const config = useContext(MainConfig)
    const dateFormatConfig = { lang, timezone: config.timezone }

    const toDateArray = (time: DateTimeMs): DateArray => {
      const date = new Date(time)
      return [date.getUTCFullYear(), 1 + date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes()]
    }

    const agendaBtn = event?.showStartDate && config.alwaysShowEventHour && (
      <CalendarButton
        event={{
          title: event.title,
          startInputType: 'utc',
          endInputType: 'utc',
          start: toDateArray(event.showStartDate),
          end: toDateArray(event.showEndDate),
          description: t('GENERAL.EVENT_CALENDAR_DESCRIPTION', { eventName: event.title }),
          location: event.scene?.address,
          url: navLink && getNavigationPermalink(navLink),
        }}
        style={display === 'large' || display === 'small' ? styles.linkButtonLarge : styles.linkButton}
      />
    )

    const ticketLinkBtn = event?.eventTicketLink && (
      <IconBtn
        icon={faTicket}
        link={event.eventTicketLink}
        style={display === 'large' || display === 'small' ? styles.linkButtonLarge : styles.linkButton}
      >
        {t('GENERAL.EVENT_TICKET_LINK')}
      </IconBtn>
    )

    const eventInfoDiv =
      (event && (
        <div style={{ marginBottom: 5 }}>
          <div style={styles.liveDateText}>
            {capitalizeDateString(formatDayLong(event.day, dateFormatConfig))}
            &nbsp;
            {showHour(event.showStartDate, config, Date.now() as DateTimeMs) &&
              (display === 'normal' ? formatHour(event.showStartDate, dateFormatConfig) : formatEndAndStartHour(event, dateFormatConfig, config))}
          </div>
          <div style={styles.livePlaceText} className={css.livePlaceText}>
            {event.scene?.name}
          </div>
          {event.price ? <div style={styles.livePlaceText}>{event.price}</div> : null}
        </div>
      )) ||
      ('location' in groupOrEvent && groupOrEvent.location && (
        <div style={{ color: MainStylesVars.colorBlockAction }}>
          <RoundIcon icon={faMapPin} style={{ color: MainStylesVars.colorBlockAction, borderColor: MainStylesVars.colorBlockAction }} />
          {groupOrEvent.location.join(', ')}
        </div>
      ))

    const mainImage = groupOrEvent.image ?? event?.image

    return (
      <Card style={{ ...styles.container, ...style }}>
        {mainImage && display !== 'small' && (
          <NavigationLink path={navLink}>
            <ResourceImage imageId={mainImage} style={styles.vignette} alt="" />
          </NavigationLink>
        )}
        <div style={styles.bottomContent}>
          {event?.soldOut ? <div style={styles.soldOut}>{t('GENERAL.SOLD_OUT')}</div> : null}
          <NavigationLink path={navLink}>
            <h3 style={styles.artistName} className={css.artistName}>
              {Array.from(new Set(['name' in groupOrEvent ? groupOrEvent.name : groupOrEvent.title, event?.title].filter(Boolean)).values()).join(
                ' - '
              )}
            </h3>
          </NavigationLink>
          {display === 'large' || display === 'small' ? (
            <>
              {eventInfoDiv}
              <div style={styles.largeDisplayButtons}>
                {agendaBtn}
                {ticketLinkBtn}
              </div>
              {event?.scene?.addressPlaceLatitude && event.scene.addressPlaceLongitude && (
                <>
                  <HorizontalBar />
                  <MapBtn
                    locationName={event.scene.address || event.scene.name}
                    position={{ lng: event.scene.addressPlaceLongitude, lat: event.scene.addressPlaceLatitude }}
                  />
                </>
              )}
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
              {eventInfoDiv}
              {ticketLinkBtn ?? agendaBtn}
            </div>
          )}
        </div>
      </Card>
    )
  },
  (prev, next) => prev.display === next.display && prev.event === next.event && prev.groupOrEvent === next.groupOrEvent
  // note that navLink and style are not supposed to change
)

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    overflow: 'visible',
  },

  vignette: {
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden',
  },

  bottomContent: {
    padding: '.8em',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
  },

  artistName: {
    margin: 0,
    marginBottom: 10,
    overflow: 'hidden',
  },

  liveDateText: {
    color: MainStylesVars.colorBlockAction,
    fontSize: '.8em',
    paddingBottom: '.2em',
    lineHeight: '1.2em',
  },

  livePlaceText: {
    fontSize: '.8em',
    paddingBottom: '.2em',
  },

  largeDisplayButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  },

  linkButton: {
    justifyContent: 'center',
  },

  linkButtonLarge: {
    justifyContent: 'center',
    flexGrow: 1,
  },

  soldOut: {
    position: 'absolute',
    top: 0,
    right: 20,
    backgroundColor: MainStylesVars.colorBlockAction,
    color: MainStylesVars.colorBlockBackground,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    fontSize: 12,
    fontWeight: 'bold',
  },
})
