function getStaticSiteUrlFromRunningScriptElement(): string {
  const thisScriptSrc = (document.currentScript as HTMLScriptElement).src
  const staticUrl = thisScriptSrc.includes('static.')
    ? thisScriptSrc.substring(0, thisScriptSrc.lastIndexOf('/')).replace(/https?:\/\//, '')
    : 'static.itg.chapi.to' // when running locally
  return staticUrl
}

export const environment: {
  embedDOMRoot: HTMLElement | null
} & ({ isBackoffice: true } | { isBackoffice: false; staticSiteUrl: string }) = {
  staticSiteUrl: getStaticSiteUrlFromRunningScriptElement(), // static.XX.chapi.to
  isBackoffice: false, // may be changed to true for the backoffice environment
  embedDOMRoot: null, // the <chapito-static> element
}
