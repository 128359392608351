import { Select } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MainConfig } from '../chapito-static'
import { Icon } from './components-lib'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import css from './lang-switch-dropdown.css'
import { WidgetBackgroundStyle } from '../pages/home'

export const LangSwitchDropdown: React.FC<{ className?: string; style?: React.CSSProperties }> = ({ className, style }) => {
  const { i18n } = useTranslation()
  const config = useContext(MainConfig)

  return i18n.languages.length > 1 ? (
    <WidgetBackgroundStyle>
      <Select
        className={`${className ?? ''} ${css.langSwitchDropdown}`}
        value={i18n.language}
        onChange={(lng) => i18n.changeLanguage(lng)}
        options={i18n.languages.map((lng) => ({ value: lng, label: lng.toUpperCase() }))}
        suffixIcon={<Icon icon={faCaretDown} color={config.colorBackground} style={{ padding: 0 }} />}
        style={style}
      />
    </WidgetBackgroundStyle>
  ) : (
    <></>
  )
}
