import React from 'react'
import { FixedWidthList } from '../components/fixed-width-list'
import { GuestCard } from '../components/guest-card'
import type { StaticGroup } from '../types'

export const GuestsPresentationList: React.FC<{ guests: StaticGroup[]; cardsPerRow: number }> = ({ guests, cardsPerRow }) => {
  return (
    <FixedWidthList itemsPerRow={cardsPerRow}>
      {guests.map((guest) => (
        <GuestCard key={guest._id} guest={guest} />
      ))}
    </FixedWidthList>
  )
}
