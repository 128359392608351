class TextService {
  _context: CanvasRenderingContext2D | null

  constructor() {
    this._context = document.createElement('canvas').getContext('2d')
  }

  getTextWidth(text: string, font: string): number | undefined {
    if (!this._context) return undefined
    this._context.font = font
    const metrics = this._context.measureText(text)
    return Math.floor(metrics.width)
  }

  splitTextNicely(text: string): string[] {
    const mid = text.length / 2
    const right = text.indexOf(' ', mid)
    const left = text.lastIndexOf(' ', mid)
    const spaceIdx = left === -1 ? right : Math.abs(right - mid) < Math.abs(left - mid) ? right : left
    if (spaceIdx <= 0) return [text]
    return [text.substring(0, spaceIdx), text.substring(spaceIdx + 1)]
  }
}

export default new TextService()
