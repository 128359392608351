// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WqHwE9NtHqebvsYB9dip:not(:hover) {\n  line-clamp: 3;\n  text-overflow: ellipsis;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 3;\n  display: -webkit-box;\n}\n\n.ns29buw5MdTFrsHOi5A4:not(:hover) {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./web/static/components/guest-or-event-card.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,4BAA4B;EAC5B,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".artistName:not(:hover) {\n  line-clamp: 3;\n  text-overflow: ellipsis;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 3;\n  display: -webkit-box;\n}\n\n.livePlaceText:not(:hover) {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"artistName": "WqHwE9NtHqebvsYB9dip",
	"livePlaceText": "ns29buw5MdTFrsHOi5A4"
};
export default ___CSS_LOADER_EXPORT___;
