import React from 'react'
import { MainStylesVars, StyleSheet } from '../stylesheet'
import type { Scene } from '../../../grab/types'
import { GuestOrEventCard } from '../components/guest-or-event-card'
import { Title } from '../components/title'
import { MapEmbed } from '../components/map-embed'
import { FixedWidthList } from '../components/fixed-width-list'
import type { StaticEvent } from '../types'

export const EventsPresentationLists: React.FC<{
  events: StaticEvent[]
  style?: React.CSSProperties
  scenesHeaders: 'none' | 'normal' | 'large'
  cardsPerRow: number
}> = ({ events, style, scenesHeaders, cardsPerRow }) => {
  if (scenesHeaders === 'none') {
    return (
      <FixedWidthList itemsPerRow={cardsPerRow}>
        {events.map((ev) => (
          <EventCard key={ev.id} event={ev} />
        ))}
      </FixedWidthList>
    )
  }

  const eventsPerPlace: Map<Scene, StaticEvent[]> = events.reduce((acc, ev) => {
    if (!ev.scene) return acc
    const list = acc.get(ev.scene)
    acc.set(ev.scene, list ? [...list, ev] : [ev])
    return acc
  }, new Map())

  return (
    <div style={{ ...styles.container, ...style }}>
      {Array.from(eventsPerPlace.keys()).map((scene) => (
        <div key={scene._id} style={{ width: '100%' }}>
          {scenesHeaders === 'large' ? (
            <>
              <div style={styles.headerContainer}>
                <span>
                  <Title level={2} style={styles.title}>
                    {scene.name}
                  </Title>
                  <span style={{ color: MainStylesVars.colorTitle }}>{scene.address}</span>
                </span>
                {scene.addressPlaceLatitude && scene.addressPlaceLongitude && (
                  <MapEmbed target={{ lng: +scene.addressPlaceLongitude, lat: +scene.addressPlaceLatitude }} style={styles.mapEmbed} />
                )}
              </div>
              <hr style={styles.hr} />
            </>
          ) : (
            <Title level={2} style={styles.title}>
              {scene.name}
            </Title>
          )}
          <FixedWidthList itemsPerRow={cardsPerRow}>
            {eventsPerPlace.get(scene)!.map((ev) => (
              <EventCard key={ev.id} event={ev} />
            ))}
          </FixedWidthList>
        </div>
      ))}
    </div>
  )
}

const EventCard: React.FC<{ event: StaticEvent }> = ({ event }) => (
  <GuestOrEventCard event={event} groupOrEvent={event} display="normal" navLink={{ page: 'event', eventId: event.id }} style={{ height: '100%' }} />
)

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },

  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  title: {
    marginBottom: 20,
  },

  mapEmbed: {
    width: '40%',
    height: 100,
    borderRadius: 10,
    border: `3px solid ${MainStylesVars.colorAction}`,
  },

  hr: {
    border: `2px solid ${MainStylesVars.colorTitle}`,
    marginTop: 8,
    marginBottom: 8,
  },
})
