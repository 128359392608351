import React from 'react'
import { ReactWebComponent } from '../react-webcomponent'
import { ChapitoStatic } from './chapito-static'
import { environment } from './environment'
import type { StaticWebComponentAttributes } from './types'

export class ChapitoStaticClass extends ReactWebComponent<StaticWebComponentAttributes> {
  constructor() {
    super({
      disableInteractions: false,
      observedAttributes: ['styles', 'data', 'pages'],
    })

    environment.embedDOMRoot = this
  }

  override render(): void {
    const specificSettings = this.attribute('specific-settings')
    const styles = this.attribute('styles')
    const data = this.attribute('data')
    const pages = this.attribute('pages')

    if (!styles) return

    if (data) environment.isBackoffice = true

    this.reactRoot.render(
      <ChapitoStatic pages={pages ?? []} specificSettings={specificSettings ?? undefined} styles={styles} fixedRawData={data ?? undefined} />
    )
  }
}

customElements.define('chapito-static', ChapitoStaticClass)
