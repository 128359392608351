import React, { useContext } from 'react'
import { MainStyles, MainStylesVars, StyleSheet, getSemiTransparentColor, MainFontsNames } from '../stylesheet'
import { MainConfig } from '../chapito-static'
import css from './page-base.css'

export const PageBase: React.FC<React.PropsWithChildren> = ({ children }) => {
  const config = useContext(MainConfig)

  const styleDefs: Record<(typeof MainStyles)[keyof typeof MainStyles], string> = {
    [MainStyles.colorBackground]: config.colorBackground,
    [MainStyles.colorBlockBackground]: config.colorBlockBackground,
    [MainStyles.colorAction]: config.colorAction,
    [MainStyles.colorActionLight]: getSemiTransparentColor(config.colorAction),
    [MainStyles.colorBlockAction]: config.colorBlockAction,
    [MainStyles.colorBlockActionLight]: getSemiTransparentColor(config.colorBlockAction),
    [MainStyles.colorBlockText]: config.colorBlockText,
    [MainStyles.colorTitle]: config.colorTitle,
    [MainStyles.colorTitleLight]: getSemiTransparentColor(config.colorTitle),
    [MainStyles.fontText]: `"${MainFontsNames.fontText}", sans-serif`,
    [MainStyles.fontTitle]: `"${MainFontsNames.fontTitle}", "Poppins", sans-serif`,
  }

  return (
    <div style={{ ...styles.container, ...StyleSheet.createDefs(styleDefs) }} className={css.pageBase}>
      {children}
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    overflowY: 'auto',
    paddingTop: 50,
    paddingBottom: 50,
    minHeight: '50vh',
    boxSizing: 'border-box',
    borderRadius: 5,
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    backgroundColor: MainStylesVars.colorBackground,
    fontFamily: MainStylesVars.fontText,
    color: MainStylesVars.colorBlockText,
  },
})
