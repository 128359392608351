import React from 'react'
import { MainStylesVars, StyleSheet } from '../stylesheet'

export const Title: React.FC<{ level?: 1 | 2 | 3; children: React.ReactElement | string; style?: React.CSSProperties; className?: string }> = ({
  level = 1,
  children,
  style,
  className,
}) => {
  const finalProps = {
    style: {
      ...styles.title,
      ...style,
      WebkitTextStrokeWidth: 0,
      fontSize: {
        1: '2em',
        2: '1.5em',
        3: '1.17em',
      }[level],
    },
    className: className,
  }
  switch (level) {
    case 1:
      return <h1 {...finalProps}>{children}</h1>
    case 2:
      return <h2 {...finalProps}>{children}</h2>
    case 3:
      return <h3 {...finalProps}>{children}</h3>
  }
}

const styles = StyleSheet.create({
  title: {
    fontFamily: MainStylesVars.fontTitle,
    color: MainStylesVars.colorTitle,
    margin: 0,
  },
})
