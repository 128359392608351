// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 600px) {\n  .Lk8B8NkqVUqSPDyToSKS {\n    gap: 10px;\n    grid-template-columns: calc(50% - 5px) calc(50% - 5px) !important;\n    font-size: small;\n  }\n}\n", "",{"version":3,"sources":["webpack://./web/static/components/fixed-width-list.css"],"names":[],"mappings":"AAAA;EACE;IACE,SAAS;IACT,iEAAiE;IACjE,gBAAgB;EAClB;AACF","sourcesContent":["@media (max-width: 600px) {\n  .fixedWidthList {\n    gap: 10px;\n    grid-template-columns: calc(50% - 5px) calc(50% - 5px) !important;\n    font-size: small;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fixedWidthList": "Lk8B8NkqVUqSPDyToSKS"
};
export default ___CSS_LOADER_EXPORT___;
