import type { StaticEvent, StaticGroup } from '../types'

function normalizeString(text: string): string {
  return text
    .normalize('NFKD')
    .toLowerCase()
    .replace(/["',;:.\-_%!&§@#*<>]/g, '')
}

function getSearchMembers(query: string): string[] {
  return normalizeString(query).split(' ')
}

function memoSearchKeys<K, V>(objectToKeys: (obj: K) => V): (obj: K) => V {
  const cachedResults = new Map<K, V>()
  return (obj: K): V => {
    const memoized: V | undefined = cachedResults.get(obj)
    if (memoized) return memoized
    const keys: V = objectToKeys(obj)
    cachedResults.set(obj, keys)
    return keys
  }
}

const getEventSearchKey = memoSearchKeys((ev: StaticEvent): string =>
  normalizeString(
    [
      ev.title,
      ev.description,
      ...ev.genres.map((g) => g.name),
      ...ev.musicGroups.map((g) => g.name),
      ...(ev.musicGroups.length === 1 ? [ev.musicGroups[0].name, ev.musicGroups[0].description ?? ''] : []),
    ].join()
  )
)

export function searchEventsByQuery(query: string, events: StaticEvent[]): StaticEvent[] {
  const searchMembers: string[] = getSearchMembers(query)
  return events.filter((ev) => {
    const evSearchRegions: string = getEventSearchKey(ev)
    return searchMembers.every((member) => evSearchRegions.includes(member))
  })
}

const getGuestSearchKey = memoSearchKeys((gr: StaticGroup): string =>
  normalizeString([gr.name, gr.description ?? '', ...gr.genres.map((g) => g.name)].join())
)

export function searchGuestsByQuery(query: string, guests: StaticGroup[]): StaticGroup[] {
  const searchMembers: string[] = getSearchMembers(query)
  return guests.filter((gr) => {
    const grSearchRegions: string = getGuestSearchKey(gr)
    return searchMembers.every((member) => grSearchRegions.includes(member))
  })
}
