import React from 'react'
import { StyleSheet } from '../stylesheet'

export const SpotifyEmbed: React.FC<{ spotifyArtistLink: string; style?: React.CSSProperties }> = ({ spotifyArtistLink, style }) => {
  const spotifyArtistId = spotifyArtistLink.substring(spotifyArtistLink.lastIndexOf('/') + 1)
  return (
    <iframe
      style={{ ...styles.frame, ...style }}
      src={`https://open.spotify.com/embed/artist/${spotifyArtistId}?utm_source=generator`}
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    />
  )
}

const styles = StyleSheet.create({
  frame: {
    border: 'none',
    borderRadius: 10,
    height: '500',
    width: '100%',
  },
})
