import React from 'react'
import { StyleSheet } from '../stylesheet'

export const YoutubeEmbed: React.FC<{ videoId: string }> = ({ videoId }) => (
  <iframe
    allowFullScreen={true}
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&widgetid=1`}
    style={styles.frame}
  />
)

const styles = StyleSheet.create({
  frame: {
    border: 'none',
    borderRadius: 10,
    overflow: 'hidden',
    width: '100%',
    aspectRatio: '16 / 9',
  },
})
