import React from 'react'
import { StyleSheet } from '../stylesheet'
import css from './fixed-width-list.css'

export const FixedWidthList: React.FC<{ itemsPerRow: number; children: React.ReactElement[] }> = ({ itemsPerRow, children }) => {
  const itemWidth: string = `calc(${100 / itemsPerRow}% - ${(styles.container.gap * (itemsPerRow - 1)) / itemsPerRow}px)`

  return (
    <div className={css.fixedWidthList} style={{ ...styles.container, gridTemplateColumns: `repeat(${itemsPerRow}, ${itemWidth})` }}>
      {children}
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'grid',
    gap: 10,
  },
})
