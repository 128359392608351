import React from 'react'
import type { StaticGroup } from '../types'
import { BadgeList, Card, Icon, NavigationLink } from '../components/components-lib'
import { ResourceImage } from '../components/resource-image'
import { useTranslation } from 'react-i18next'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { MainStylesVars } from '../stylesheet'
import { FixedWidthList } from '../components/fixed-width-list'

export function GuestsCompactList(props: { guests: StaticGroup[]; cardsPerRow?: number }): JSX.Element {
  const { guests } = props
  return (
    <FixedWidthList itemsPerRow={props.cardsPerRow ?? 2}>
      {guests.map((g) => (
        <GuestCardCompact key={g._id} guest={g} />
      ))}
    </FixedWidthList>
  )
}

function GuestCardCompact(props: { guest: StaticGroup }): JSX.Element {
  const { t } = useTranslation()

  const { guest } = props
  return (
    <NavigationLink style={{ display: 'grid' }} path={{ page: 'guest', guestId: guest._id }}>
      <Card style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
        <h3 style={{ marginBlock: 0 }}>{guest.name}</h3>
        <div style={{ display: 'flex', gap: 10 }}>
          <ResourceImage
            imageId={guest.image}
            alt={t('GENERAL.IMAGE_ALT_GUEST', { guest: guest.name })}
            style={{
              width: 90,
              borderRadius: 10,
              overflow: 'hidden',
              marginBlock: 'auto',
            }}
          />
          <div>
            {guest.genres && <BadgeList size="small" labels={guest.genres.map((g) => g.name)} />}

            {guest.location && (
              <p>
                <Icon color={MainStylesVars.colorBlockText} icon={faLocationDot} />
                {guest.location.join(', ')}
              </p>
            )}
          </div>
        </div>
      </Card>
    </NavigationLink>
  )
}
