// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qu677aACWl5hxYKILDDO {\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n}\n\n.qu677aACWl5hxYKILDDO > div {\n  flex: 1;\n  max-width: 350px;\n}\n@media (max-width: 600px) {\n  .PVUrVsFCp4MB3sIvb4fZ {\n    flex-direction: column;\n  }\n  .qu677aACWl5hxYKILDDO {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n}\n\n@media (min-width: 601px) {\n  .wqdLpCSryTbXgP7fo2XK {\n    max-width: 35%;\n  }\n}\n\n.GAHTRzxB6GDec9Dux6a3 > div > div {\n  align-items: center !important;\n}\n", "",{"version":3,"sources":["webpack://./web/static/pages/guest-or-event.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,OAAO;EACP,gBAAgB;AAClB;AACA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV;AACF;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".two-cols {\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n}\n\n.two-cols > div {\n  flex: 1;\n  max-width: 350px;\n}\n@media (max-width: 600px) {\n  .guestOrEventPageBody {\n    flex-direction: column;\n  }\n  .two-cols {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n}\n\n@media (min-width: 601px) {\n  .guestOrEventPageColumnLeft {\n    max-width: 35%;\n  }\n}\n\n.collapseRoot > div > div {\n  align-items: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"two-cols": "qu677aACWl5hxYKILDDO",
	"guestOrEventPageBody": "PVUrVsFCp4MB3sIvb4fZ",
	"guestOrEventPageColumnLeft": "wqdLpCSryTbXgP7fo2XK",
	"collapseRoot": "GAHTRzxB6GDec9Dux6a3"
};
export default ___CSS_LOADER_EXPORT___;
