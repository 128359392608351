import React, { useState } from 'react'
import { ResourceImage } from './resource-image'
import { StyleSheet } from '../stylesheet'
import { Card, NavigationLink } from './components-lib'
import { useLang } from '../chapito-static'
import { useTranslation } from 'react-i18next'
import type { StaticGroup } from '../types'
import { Title } from './title'

export const GuestCard: React.FC<{ guest: StaticGroup }> = ({ guest }) => {
  const lang = useLang()
  const { t } = useTranslation()

  const [isHovered, setHovered] = useState<boolean>(false)

  return (
    <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <NavigationLink path={{ page: 'guest', guestId: guest._id }}>
        <Card style={{ ...styles.container, transform: isHovered ? 'scale(1.05) rotate(2deg)' : 'scale(1)', zIndex: isHovered ? 10 : 0 }}>
          <div style={styles.containerName}>
            {guest.image ? (
              <>
                <div style={styles.dropShadow} />
                <ResourceImage imageId={guest.image} alt={t('GENERAL.IMAGE_ALT_GUEST', { guest: guest.name })} style={styles.image} />
              </>
            ) : (
              <div style={{ ...styles.dropShadow, height: '100%' }} />
            )}
            <Title level={3} style={guest.image ? styles.name : styles.nameCentered}>
              {guest.name.toLocaleUpperCase(lang)}
            </Title>
          </div>
        </Card>
      </NavigationLink>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    padding: 0,
    aspectRatio: 1,
    transition: 'all .3s',
  },

  image: {
    width: '100%',
    height: '100%',
  },

  name: {
    position: 'absolute',
    bottom: 15,
    textAlign: 'center',
    left: '5%',
    width: '90%',
    color: 'white',
  },

  containerName: { display: 'flex', width: '100%', height: '100%', placeItems: 'center' },
  nameCentered: {
    position: 'absolute',
    textAlign: 'center',
    width: '90%',
    padding: '5%',
    color: 'white',
  },

  dropShadow: {
    background: 'linear-gradient(0deg, rgba(0,0,0,0.595098107602416) 18%, rgba(255,255,255,0) 100%)',
    height: '30%',
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
})
