import type { DateTimeMs } from '../grab/types'
import type { EventFilterable, EventSortable } from './events'

export const getSortEventsByWeightDesc = (
  orderEventsByEndDate: boolean,
  sortByDay: boolean
): ((event1: EventSortable, event2: EventSortable) => number) => {
  return (event1: EventSortable, event2: EventSortable): number => {
    if (sortByDay && event1.day !== event2.day) {
      return sortByDayDesc(event1, event2)
    }
    if (event1.weight !== event2.weight) {
      return sortByWeight(event1, event2)
    }
    return getSortEventsByDateDesc(orderEventsByEndDate)(event1, event2)
  }
}

export const getSortEventsByDateDesc = (orderEventsByEndDate: boolean): ((event1: EventSortable, event2: EventSortable) => number) => {
  return orderEventsByEndDate ? sortEventsByEndDateDesc : sortEventsByStartDateDesc
}

function sortEventsByEndDateDesc(event1: EventSortable, event2: EventSortable): number {
  return sortEventsByDateDesc(event1, event2, (e) => e.showEndDate)
}
function sortEventsByStartDateDesc(event1: EventSortable, event2: EventSortable): number {
  return sortEventsByDateDesc(event1, event2, (e) => e.showStartDate)
}

const sortEventsByDateDesc = (event1: EventSortable, event2: EventSortable, toDate: (e: EventSortable) => DateTimeMs | undefined): number => {
  const date1 = toDate(event1)
  const date2 = toDate(event2)
  if (date1 !== date2) {
    return (date1 ?? 0) - (date2 ?? 0)
  }
  return event1.title.localeCompare(event2.title)
}

export const sortByDayDesc = (event1: { day: DateTimeMs }, event2: { day: DateTimeMs }): number => {
  const date1 = event1.day
  const date2 = event2.day
  return (date1 ?? 0) - (date2 ?? 0)
}

export const filterOutCurrentAndPastEvents =
  (now: DateTimeMs) =>
  (event: EventFilterable): boolean => {
    return !!event.showStartDate && event.showStartDate > now
  }

export const filterCurrentEvents =
  (now: DateTimeMs) =>
  (event: EventFilterable): boolean => {
    return isLive(event, now)
  }

export const isLive = (event: EventFilterable, now: DateTimeMs): boolean => {
  return !!event.showStartDate && event.showStartDate <= now && !!event.showEndDate && event.showEndDate >= now
}

export const sortByWeight = (sort1: { weight: number | undefined }, sort2: { weight: number | undefined }): number => {
  return -((sort1.weight ?? 0) - (sort2.weight ?? 0))
}

// sort by weight if present, null last sorts alphanumeric
export const sortByWeightAndName = (
  sort1: { weight: number | undefined; name: string },
  sort2: { weight: number | undefined; name: string }
): number => {
  if (sort1.weight !== sort2.weight) {
    return sortByWeight(sort1, sort2)
  }
  return sort1.name.localeCompare(sort2.name)
}
