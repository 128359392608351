import React, { useCallback, useMemo } from 'react'
import type { DateTimeMs } from '../grab/types'

// note: the context contains a date *provider*, not a date object, to
// avoid re-rendering every second each element that requires a date.
// if you need periodic updates you will have to create a timeout that
// fetches the date every so often

// the date attribute can also be set to Date.now() to cause a rerender
// of all child elements that depend on the current time each time the
// element containing the provider is re-rendered

type CurrentDateProvider = () => DateTimeMs
export const CurrentDateContext = React.createContext<CurrentDateProvider>(() => Date.now() as DateTimeMs)

export const FakeDateProvider: React.FC<{ fakeDate?: DateTimeMs; children: React.ReactNode }> = ({ fakeDate, children }) => {
  const fakeDateSetTime = useMemo(Date.now, [fakeDate])

  const getCurrentTime: CurrentDateProvider = useCallback(() => {
    return fakeDate ? ((fakeDate + (Date.now() - fakeDateSetTime)) as DateTimeMs) : (Date.now() as DateTimeMs)
  }, [fakeDateSetTime])

  return <CurrentDateContext.Provider value={getCurrentTime}>{children}</CurrentDateContext.Provider>
}
