export class StyleSheet {
  static create<Styles extends Record<string, React.CSSProperties>>(styles: Styles): Styles {
    return styles
  }

  static createDefs<Styles extends { [K in keyof Styles]: K extends `--${string}` ? string : never }>(
    styles: Styles
  ): Record<keyof Styles, React.CSSProperties> {
    return styles
  }
}

// assuming the passed hexCode respects one of the #rrggbb or #rrggbbaa formats
export function getSemiTransparentColor(hexCode: string): string {
  const currentAlpha: number = parseInt(hexCode.substring(7), 16) || 0xff
  return (
    hexCode.substring(0, 7) +
    Math.round(currentAlpha * 0.15)
      .toString(16)
      .padStart(2, '0')
  )
}

// can be used from anywhere, but prefer using MainStylesVars.fontXX inside <PageBase>
export const MainFontsNames = {
  fontTitle: 'ChapitoTitle',
  fontText: 'ChapitoText',
}

export const MainStyles = {
  fontTitle: '--chapito-font-title',
  fontText: '--chapito-font-text',
  colorTitle: '--chapito-color-title',
  colorTitleLight: '--chapito-color-title-light',
  colorBackground: '--chapito-color-background',
  colorBlockBackground: '--chapito-color-block-background',
  colorAction: '--chapito-color-action',
  colorActionLight: '--chapito-color-action-light',
  colorBlockAction: '--chapito-color-block-action',
  colorBlockActionLight: '--chapito-color-block-action-light',
  colorBlockText: '--chapito-color-block-text',
} as const

export const MainStylesVars = {
  fontTitle: 'var(--chapito-font-title)',
  fontText: 'var(--chapito-font-text)',
  colorTitle: 'var(--chapito-color-title)',
  colorTitleLight: 'var(--chapito-color-title-light)',
  colorBackground: 'var(--chapito-color-background)',
  colorBlockBackground: 'var(--chapito-color-block-background)',
  colorAction: 'var(--chapito-color-action)',
  colorActionLight: 'var(--chapito-color-action-light)',
  colorBlockAction: 'var(--chapito-color-block-action)',
  colorBlockActionLight: 'var(--chapito-color-block-action-light)',
  colorBlockText: 'var(--chapito-color-block-text)',
} as const
