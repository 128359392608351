export const exhaustiveCheck = (_param: never): never => {
  throw new Error(`exhaustive check [${String(_param as unknown)}] not handled`)
}

/**
 * Same as Object.keys() but with better typing
 * */
export function keys<T extends object>(o: T): Array<keyof T> {
  return Object.keys(o) as Array<keyof T>
}

/**
 * Not nullish typeguard
 * */
export function isNotNullish<T>(value: T | null | undefined): value is T {
  return value != null
}

type ObjectEntry<BaseType> = [keyof BaseType, BaseType[keyof BaseType]]
/**
 * Same as Object.entries() but with better typing
 * */
export function entries<T extends object>(obj: T): Array<ObjectEntry<T>> {
  return Object.entries(obj) as Array<ObjectEntry<T>>
}

/**
 * Same as Array.includes() but with better typing
 * */
export function includes<T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T {
  return coll.includes(el as T)
}

/**
 * Same as Object.fromEntries() but with better typing
 * */
export function fromEntries<K extends string, T>(entries: Iterable<readonly [K, T]>): { [k in K]: T } {
  return Object.fromEntries(entries) as { [k in K]: T }
}
