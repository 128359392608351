import ReactDOM from 'react-dom/client'

type AttributeName<Attributes> = Extract<keyof Attributes, string>

export class ReactWebComponent<Attributes> extends HTMLElement {
  public observer
  protected reactRoot

  constructor({
    observedAttributes,
    disableInteractions,
  }: {
    observedAttributes?: AttributeName<Attributes>[] | undefined
    disableInteractions: boolean
  }) {
    super()
    this.reactRoot = ReactDOM.createRoot(this)
    this.observer = new MutationObserver(() => this.render())
    this.observer.observe(this, { attributes: true, attributeFilter: observedAttributes })
    if (disableInteractions) this.addEventListener('click', (e) => e.stopPropagation(), { capture: true })
  }

  attribute<A extends AttributeName<Attributes>>(name: A): Attributes[A] | null {
    const attr = this.getAttribute(name)
    return attr && JSON.parse(attr)
  }

  // those are lifecycle callbacks for webcomponents
  // cf https://developer.mozilla.org/en-US/docs/Web/API/Web_components/Using_custom_elements#custom_element_lifecycle_callbacks
  /** @knipignore */
  connectedCallback(): void {
    this.render()
  }

  /** @knipignore */
  disconnectedCallback(): void {
    this.reactRoot.unmount()
    this.observer.disconnect()
  }

  // overrideable
  render(): void {}
}
