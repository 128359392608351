// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 600px) {\n  .TrkSWCbPhasw5q6sLxy9 {\n    text-orientation: sideways;\n    writing-mode: vertical-lr;\n    transform: rotate(180deg);\n  }\n\n  ._7njB3iXSNtHdtHxUWEdf {\n    margin-right: 30px;\n  }\n}\n\n@media (min-width: 600px) {\n  ._7njB3iXSNtHdtHxUWEdf {\n    margin-right: 30px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./web/static/pages/events-timeline.css"],"names":[],"mappings":"AAAA;EACE;IACE,0BAA0B;IAC1B,yBAAyB;IACzB,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["@media (max-width: 600px) {\n  .title {\n    text-orientation: sideways;\n    writing-mode: vertical-lr;\n    transform: rotate(180deg);\n  }\n\n  .titleContainer {\n    margin-right: 30px;\n  }\n}\n\n@media (min-width: 600px) {\n  .titleContainer {\n    margin-right: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "TrkSWCbPhasw5q6sLxy9",
	"titleContainer": "_7njB3iXSNtHdtHxUWEdf"
};
export default ___CSS_LOADER_EXPORT___;
