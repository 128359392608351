import React from 'react'
import { IconBtn } from './components-lib'
import { useTranslation } from 'react-i18next'
import type { EventAttributes } from 'ics'
import { createEvent } from 'ics'
import { saveAs } from 'file-saver'
import { MainStylesVars, StyleSheet } from '../stylesheet'
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons'

export const CalendarButton: React.FC<{ event: EventAttributes & { title: string }; style?: React.CSSProperties }> = ({ event, style }) => {
  const { t } = useTranslation()

  const downloadICSFile = (): void => {
    createEvent(event, (err, val) => {
      if (err) return
      const blob = new Blob([val], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `${event.title}.ics`)
    })
  }

  return (
    <IconBtn icon={faCalendarPlus} style={{ ...styles.button, ...style }} onClick={downloadICSFile}>
      {t('GENERAL.ADD_TO_CALENDAR')}
    </IconBtn>
  )
}

const styles = StyleSheet.create({
  button: {
    color: MainStylesVars.colorBlockAction,
    backgroundColor: MainStylesVars.colorBlockBackground,
    borderColor: MainStylesVars.colorBlockAction,
  },
})
