import React from 'react'
import type { TransformedImageId } from '../../../grab/types'
import { StyleSheet } from '../stylesheet'
import { environment } from '../environment'

export const ResourceImage: React.FC<{ imageId: TransformedImageId | undefined; alt: string; style?: React.CSSProperties }> = ({
  imageId,
  alt,
  style,
}) => {
  let finalUrl: string | undefined
  if (imageId && typeof imageId === 'string') {
    const [imageBaseId, imageExt] = imageId.split('.', 2)
    if (environment.isBackoffice) {
      finalUrl = `/api/files/${imageBaseId.substring(1)}`
    } else {
      finalUrl = `https://${environment.staticSiteUrl}/images/${imageBaseId}_square.${imageExt}`
    }
  } else {
    return null
  }

  return (
    <div style={{ ...styles.container, ...style }}>
      <img style={styles.image} src={finalUrl} alt={alt} />
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    aspectRatio: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  image: {
    display: 'block',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
})
