import React, { useContext, useEffect, useState } from 'react'
import type { DateDay, DateTimeMs, EventId, GenreId, GroupId, ProgramId, SceneId } from '../../grab/types'
import { HomePage } from './pages/home'
import type { StaticGroup, StaticEvent, StaticData } from './types'
import type { StaticMainConfig } from './chapito-static'
import { MainData } from './chapito-static'
import { EventPage, GuestPage } from './pages/guest-or-event'
import { includes, keys } from '../../services/typescript'
import { environment } from './environment'
import { getDateDay } from '../../services/date'

type NavigationContext = {
  setUrl: (url: string, stateAction?: 'replace' | 'push') => void
  search: URLSearchParams
}

export const Navigation = React.createContext<NavigationContext>({} as NavigationContext)

/*
 Routes are encoded into search parameters
 - ?event=<eventId>  an event page
 - ?guest=<guestId>  a guest page
 - (default)         the home page
   - ?view=guests      a list of guests
   - ?view=timeline    a list of events, arranged in a time table
   - ?view=events      a list of all events, grouped by scenes
 We cannot use paths because we are targeting a web-component integration,
 that musn't mess with the embedding app routes
*/
export type AppPath = { page: 'home' } | { page: 'event'; eventId: EventId } | { page: 'guest'; guestId: GroupId }
export const RouteParamNames = {
  eventRouteEventId: 'event',
  guestRouteGuestId: 'guest',
} as const

function isUrlHomePage(url: string): boolean {
  const params = new URLSearchParams(url)
  return !params.get(RouteParamNames.eventRouteEventId) && !params.get(RouteParamNames.guestRouteGuestId)
}

const NO_RECORDED_Y_SCROLL: number = -12345
const homeScrollState: {
  pageScrollY: number
  initialLoad: boolean
} = {
  pageScrollY: NO_RECORDED_Y_SCROLL,
  initialLoad: true,
}

export const PageRouter: React.FC<{ defaultView: number }> = ({ defaultView }) => {
  const [url, setUrl] = useState<string>(window.location.search)
  const [wasHomePage, setWasHomePage] = useState<boolean>(true)
  const isHomePage = isUrlHomePage(url)

  // during navigation, keep home page scroll state or scroll to top of other pages
  useEffect(() => {
    if (homeScrollState.initialLoad) return
    if (!isHomePage || (!wasHomePage && homeScrollState.pageScrollY === NO_RECORDED_Y_SCROLL)) {
      environment.embedDOMRoot?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' })
    } else if (!wasHomePage) {
      window.scrollTo(0, homeScrollState.pageScrollY)
    }
  })

  // if the page was opened through a shared link, scroll to the widget embed
  useEffect(() => {
    if (!homeScrollState.initialLoad) return
    homeScrollState.initialLoad = false

    // called after the user goes back and forth in their navigation history
    window.addEventListener('popstate', () => setUrl(window.location.search))

    const initialSearch = new URLSearchParams(window.location.search)
    if (
      Object.values(RouteParamNames).some((param) => initialSearch.has(param)) ||
      Object.values(globalQueryUrlParamNames).some((param) => initialSearch.has(param))
    )
      environment.embedDOMRoot?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  }, [])

  const setURL = (newUrl: string, stateAction: 'replace' | 'push' = 'push'): void => {
    if (isHomePage && !isUrlHomePage(newUrl)) homeScrollState.pageScrollY = window.scrollY // note: this won't work if the web-component is embedded is a context with a non-window-global scrollbar
    setUrl(newUrl)
    setWasHomePage(isHomePage)
    // won't trigger a window.popstate event
    if (!environment.isBackoffice && stateAction === 'push') history.pushState(undefined, '', newUrl)
    else history.replaceState(undefined, '', newUrl)
  }

  const data = useContext(MainData)
  const search = new URLSearchParams(window.location.search)

  const guestIdOfGuestPage: GroupId | null = search.get(RouteParamNames.guestRouteGuestId) as GroupId | null
  const guestOfGuestPage: StaticGroup | null = guestIdOfGuestPage && (data.groups.find((g) => g._id === guestIdOfGuestPage) ?? null)
  const eventIdOfEventPage: EventId | null = search.get(RouteParamNames.eventRouteEventId) as EventId | null
  const eventOfEventPage: StaticEvent | null = eventIdOfEventPage && (data.events.find((e) => e.id === eventIdOfEventPage) ?? null)

  return (
    <Navigation.Provider value={{ setUrl: setURL, search: search }}>
      {guestOfGuestPage ? (
        <GuestNavigationPage guest={guestOfGuestPage} />
      ) : eventOfEventPage ? (
        <EventNavigationPage event={eventOfEventPage} />
      ) : (
        <HomePage data={data} defaultView={defaultView} />
      )}
    </Navigation.Provider>
  )
}

const GuestNavigationPage: React.FC<{ guest: StaticGroup }> = ({ guest }) => {
  const artistEvents: StaticEvent[] = guest.relatedEvents
  return <GuestPage guest={guest} events={artistEvents} />
}

const EventNavigationPage: React.FC<{ event: StaticEvent }> = ({ event }) => {
  const data = useContext(MainData)
  const guests: StaticGroup[] = event.musicGroups
  const relatedEvents: StaticEvent[] = data.events.filter((ev) => guests.some((g) => ev.musicGroups.includes(g))).filter((ev) => ev.id !== event.id)
  if (guests.length === 1 && guests[0].name === event.title) return <GuestPage guest={guests[0]} events={[event, ...relatedEvents]} />
  return <EventPage event={event} guests={guests} relatedEvents={relatedEvents} />
}

export type GuestSortOrder = 'relevance' | 'alphabetical'
export type EventsSortOrder = 'relevance' | 'place' | 'time' | 'alphabetical'
export type HomePageView = 'EVENTS' | 'TIMELINE' | `GUESTS` | 'GUESTS_COMPACT'

export type GlobalQuery = {
  viewDate: DateDay | null
  searchQuery: string
  filterPlaces: SceneId[]
  filterGenres: GenreId[]
  filterPrograms: ProgramId[]
  view: number
  eventsSortOrder: EventsSortOrder
  guestsSortOrder: GuestSortOrder
}

const DEFAULT_GUESTS_SORT_ORDER: GuestSortOrder = 'relevance'
const DEFAULT_EVENTS_SORT_ORDER: EventsSortOrder = 'time'

export const globalQueryUrlParamNames: Record<keyof GlobalQuery, string> = {
  filterGenres: 'genres',
  filterPlaces: 'places',
  filterPrograms: 'programs',
  searchQuery: 'q',
  guestsSortOrder: 'g',
  eventsSortOrder: 'e',
  view: 'view',
  viewDate: 'date',
}

export const loadGlobalQuery = (
  config: StaticMainConfig,
  data: StaticData,
  urlParams: URLSearchParams,
  defaultView: number,
  availableDates: DateDay[]
): GlobalQuery => {
  const { scenes, genres, programs } = data

  const getValidIds = <Id extends string>(list: string[] | undefined, ids: Id[]): Id[] => list?.filter((i): i is Id => includes(ids, i)) ?? []
  const getParam = (param: keyof GlobalQuery): string | null => urlParams.get(globalQueryUrlParamNames[param])
  const isValidEventOrder = (order: string): order is EventsSortOrder =>
    includes(['alphabetical', 'place', 'relevance', 'time'] satisfies EventsSortOrder[], order)
  const isValidGuestOrder = (order: string): order is GuestSortOrder => includes(['alphabetical', 'relevance'] satisfies GuestSortOrder[], order)

  const viewDateStr: string | null = getParam('viewDate')
  const searchQuery: string | null = getParam('searchQuery')
  const filterPlaces: string | null = getParam('filterPlaces')
  const filterGenres: string | null = getParam('filterGenres')
  const filterPrograms: string | null = getParam('filterPrograms')
  const viewStr: string | null = getParam('view')
  const eventsSortOrder: string | null = getParam('eventsSortOrder')
  const guestsSortOrder: string | null = getParam('guestsSortOrder')

  // pick the available date that is the closest to the target date
  const targetViewDate: DateDay = (viewDateStr as DateDay | null) ?? getDateDay(Date.now() as DateTimeMs, config)
  const viewDate: DateDay | null = !availableDates.length
    ? null
    : availableDates.reduce(
        (acc, date) =>
          Math.abs(new Date(acc).getTime() - new Date(targetViewDate).getTime()) <
          Math.abs(new Date(date).getTime() - new Date(targetViewDate).getTime())
            ? acc
            : date,
        availableDates[0]
      )

  return {
    view: +(viewStr ?? defaultView),
    viewDate: viewDate,
    searchQuery: searchQuery ?? '',
    filterPlaces: getValidIds(
      filterPlaces?.split(',') ?? undefined,
      scenes.map((s) => s._id)
    ),
    filterGenres: getValidIds(
      filterGenres?.split(',') ?? undefined,
      genres.map((g) => g._id)
    ),
    filterPrograms: getValidIds(
      filterPrograms?.split(',') ?? undefined,
      programs.map((p) => p._id)
    ),
    eventsSortOrder: eventsSortOrder && isValidEventOrder(eventsSortOrder) ? eventsSortOrder : DEFAULT_EVENTS_SORT_ORDER,
    guestsSortOrder: guestsSortOrder && isValidGuestOrder(guestsSortOrder) ? guestsSortOrder : DEFAULT_GUESTS_SORT_ORDER,
  }
}

export const getURLParamsForGlobalQuery = (config: StaticMainConfig, query: GlobalQuery, defaultView: number): string => {
  const params: string = keys(query).reduce((acc, key) => {
    let val: string

    // serialize members, omiting defaults
    if (key === 'viewDate' && query[key]) {
      const roundedViewDate = query[key]
      val = roundedViewDate === getDateDay(Date.now() as DateTimeMs, config) ? '' : roundedViewDate.substring(0, 10)
    } else if (key === 'guestsSortOrder' && query[key] === DEFAULT_GUESTS_SORT_ORDER) {
      val = ''
    } else if (key === 'eventsSortOrder' && query[key] === DEFAULT_EVENTS_SORT_ORDER) {
      val = ''
    } else if (key === 'view' && query[key] === defaultView) {
      val = ''
    } else {
      val = query[key]?.toString() ?? ''
    }

    if (!val || (Array.isArray(val) && !val.length)) return acc
    const encoded = `${encodeURIComponent(globalQueryUrlParamNames[key])}=${encodeURIComponent(val + '')}`
    return acc ? `${acc}&${encoded}` : encoded
  }, '')

  return `?${params}${window.location.hash}`
}
