// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 600px) {\n  .qJKcMzZxUnXJSzyVIgbQ {\n    padding-left: 10px;\n    padding-right: 10px;\n  }\n}\n\n@media (min-width: 601px) {\n  .qJKcMzZxUnXJSzyVIgbQ {\n    padding-left: 60px;\n    padding-right: 60px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./web/static/pages/page-base.css"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":["@media (max-width: 600px) {\n  .pageBase {\n    padding-left: 10px;\n    padding-right: 10px;\n  }\n}\n\n@media (min-width: 601px) {\n  .pageBase {\n    padding-left: 60px;\n    padding-right: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageBase": "qJKcMzZxUnXJSzyVIgbQ"
};
export default ___CSS_LOADER_EXPORT___;
