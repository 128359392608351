import type { UIEventHandler } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { DateDay, DateTimeMs, Scene } from '../../../grab/types'
import { useTranslation } from 'react-i18next'
import { useLang } from '../chapito-static'
import { MainFontsNames, MainStylesVars, StyleSheet } from '../stylesheet'
import { Title } from '../components/title'
import { HorizontalBar, NavigationLink } from '../components/components-lib'
import { ResourceImage } from '../components/resource-image'
import type { StaticEvent } from '../types'
import type { ScrollStore } from '../components/draggable'
import { Draggable } from '../components/draggable'
import TextService from '../services/text-service'
import css from './events-timeline.css'
import { sortByWeight } from '../../../services/sort'
import { formatHour } from '../../../services/date'

type EventsTimelineVueProps = {
  events: StaticEvent[]
  date: DateDay | null
  timezone: string
}

type WithNonNull<T, NonNullFields extends keyof T> = T & { [P in NonNullFields]: NonNullable<T[P]> }

const showHasScene = (ev: StaticEvent): ev is WithNonNull<StaticEvent, 'scene'> => !!ev.scene
const eventsOverlap = (e1: StaticEvent, e2: StaticEvent): boolean => e1.showStartDate < e2.showEndDate && e1.showEndDate > e2.showStartDate

const stripsColors: [string, string] = [MainStylesVars.colorTitle, MainStylesVars.colorAction]

const timelinePageScrollStore: ScrollStore = { positionX: 0, positionY: 0 }

export const EventsTimelineVue: React.FC<EventsTimelineVueProps> = ({ events, date, timezone }) => {
  const { t } = useTranslation()
  const lang = useLang()
  const [leftShadowVisible, setLeftShadowsVisible] = useState<boolean>(false)
  const [rightShadowVisible, setRightShadowsVisible] = useState<boolean>(true)

  const scenes: Scene[] = [...new Set(events.filter(showHasScene).map((e) => e.scene))].sort(sortByWeight)
  const scenelessEvents: StaticEvent[] = events.filter((e) => !e.scene)
  const verticalMarksOffset = 1000 * 60 * 30 // 30min between marks
  const minMarkCount = 20

  let firstGridTime: number = Math.min(...events.map((e) => e.showStartDate))
  let lastGridTime: number = Math.max(...events.map((e) => e.showEndDate))
  firstGridTime = Math.floor(firstGridTime / verticalMarksOffset) * verticalMarksOffset
  lastGridTime = Math.ceil(lastGridTime / verticalMarksOffset) * verticalMarksOffset
  if ((lastGridTime - firstGridTime) / verticalMarksOffset < minMarkCount) {
    const middle = Math.floor((firstGridTime + lastGridTime) / 2 / verticalMarksOffset) * verticalMarksOffset
    firstGridTime = middle - (minMarkCount / 2) * verticalMarksOffset
    lastGridTime = middle + (minMarkCount / 2) * verticalMarksOffset
  }
  firstGridTime = Math.floor(firstGridTime / (1000 * 60 * 60)) * 1000 * 60 * 60 // force the grid to start at HH:00

  const eventsLevels = new Map<StaticEvent, number>()
  const scenesLevels = new Map<Scene, number>()
  let totalGridHeight: number = 0
  for (const scene of scenes) {
    scenesLevels.set(scene, totalGridHeight)
    const sceneEvents = events.filter((e) => e.scene === scene)
    const levels: number[] = Array(sceneEvents.length).fill(-1)
    for (let i = 0; i < sceneEvents.length; i++) {
      let fits = false
      while (!fits) {
        levels[i]++
        fits = true
        for (let j = 0; j < i; j++) {
          if (levels[i] === levels[j] && eventsOverlap(sceneEvents[i], sceneEvents[j])) {
            fits = false
            break
          }
        }
      }
      eventsLevels.set(sceneEvents[i], levels[i])
    }
    totalGridHeight += Math.max(...levels) + 1
  }

  const rowCount: number = 1 + totalGridHeight
  const colCount: number = Math.floor((lastGridTime - firstGridTime) / verticalMarksOffset)
  const everyNLabel: number = 2
  const timeToGridX = (t: DateTimeMs): number => (t - firstGridTime) / verticalMarksOffset

  const isEventLargeEnoughForVignette = (ev: StaticEvent): boolean => ev.showEndDate - ev.showStartDate > 1000 * 60 * 35 // 35min min to display vignettes

  /*
  Grid structure:
  - first row is used for timestamps (ie. 14:00)
  - first R column is for scenes, where R is gridResolutionX, titles are sticky
  - next NxM rows and columns are for events, with one timestamp and vertical
    bar every R columns
  - the last row is used as padding, only the vertical bars go that far

  gridResolutionX is used to have events at not-round times (ie. if gridResolutionX
  is 4, we can use 12:00, 12:15, 12:30 and 12:45).
  */

  const gridResolutionX = 60
  const gridArea = (x: number, y: number, w: number = 1, h: number = 1): string =>
    `${1 + Math.round(y)} / ${1 + Math.round(x * gridResolutionX)} / ${1 + Math.round(y + h)} / ${1 + Math.round((x + w) * gridResolutionX)}`

  const TimelineRow: React.FC<{ sceneName: string; events: StaticEvent[]; row: number }> = ({ sceneName, events, row }) => {
    return (
      <>
        <div
          style={{
            gridArea: gridArea(0, row, 1, Math.max(...events.map((e) => eventsLevels.get(e) ?? 0)) + 1),
            padding: 5,
            position: 'sticky',
            left: 0,
            zIndex: 2,
          }}
          className={css.titleContainer}
        >
          <HorizontalBar style={{ backgroundColor: MainStylesVars.colorTitle, marginTop: 0, minWidth: '1rem', height: 1 }} />
          <Title level={3} className={css.title}>
            {sceneName.toLocaleUpperCase(lang)}
          </Title>
        </div>
        {events.map((ev) => (
          <SmallEventCard
            key={ev.id}
            event={ev}
            gridArea={gridArea(
              1 + timeToGridX(ev.showStartDate),
              row + (eventsLevels.get(ev) ?? 0),
              timeToGridX(ev.showEndDate) - timeToGridX(ev.showStartDate),
              1
            )}
            small={!isEventLargeEnoughForVignette(ev)}
          />
        ))}
      </>
    )
  }

  const scrollHandler = useCallback<UIEventHandler>((ev) => {
    setLeftShadowsVisible(ev.currentTarget.scrollLeft !== 0)
    setRightShadowsVisible(ev.currentTarget.scrollLeft < ev.currentTarget.scrollWidth - ev.currentTarget.clientWidth)
  }, [])

  return (
    (!events.length && (
      <Title level={2} style={{ marginBottom: 10, textAlign: 'center', marginTop: 60 }}>
        {t('TIMELINE.NO_DATED_EVENTS', { date: new Date(date ?? 0).toLocaleDateString(lang) })}
      </Title>
    )) || (
      <Draggable style={{ position: 'relative' }} scrollStore={timelinePageScrollStore}>
        <div
          style={{
            gridTemplate: `minmax(auto, 20px) repeat(${rowCount - 1}, minmax(${
              styles.smallEventCard.height + 5
            }px, auto)) 20vh / repeat(${gridResolutionX}, auto) repeat(${colCount * gridResolutionX}, ${110 / gridResolutionX}px)`,
            ...styles.gridContainer,
          }}
          onScroll={scrollHandler}
        >
          {Array(colCount + 1)
            .fill(0)
            .map((_, i) => (
              <div
                key={`line-${i}`}
                style={{
                  gridArea: gridArea(1 + i, 1, 1, rowCount),
                  backgroundColor: stripsColors[i % 2],
                  width: 1,
                }}
              />
            ))}
          {Array(colCount + 1)
            .fill(0)
            .map(
              (_, i) =>
                i % everyNLabel === 0 && (
                  <div
                    key={`date-${i}`}
                    style={{
                      gridArea: gridArea(1 + i, 0),
                      transform: 'translateX(-50%)',
                      textAlign: 'center',
                      width: 100,
                      color: stripsColors[i % 2],
                    }}
                  >
                    {formatHour((firstGridTime + verticalMarksOffset * i) as DateTimeMs, { lang, timezone })}
                  </div>
                )
            )}
          <div
            style={{
              gridArea: gridArea(0, 0, 1, rowCount + 1),
              ...styles.titlesStickyPanel,
              ...(leftShadowVisible ? { boxShadow: 'rgb(0, 0, 0) 4px 0px 38px -10px' } : {}),
            }}
          />

          {scenes.map((s) => (
            <TimelineRow key={s._id} sceneName={s.name} events={events.filter((e) => e.scene === s)} row={1 + (scenesLevels.get(s) ?? 0)} />
          ))}
          {scenelessEvents.length ? <TimelineRow sceneName={t('TIMELINE.NO_SCENE')} events={scenelessEvents} row={1 + scenes.length} /> : <></>}
        </div>
        <div style={{ ...styles.rightShadow, ...(rightShadowVisible ? {} : { display: 'none' }) }} />
      </Draggable>
    )
  )
}

const SmallEventCard: React.FC<{ event: StaticEvent; gridArea: React.CSSProperties['gridArea']; small: boolean }> = ({ event, gridArea, small }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isDOMLoaded, setDOMLoaded] = useState<boolean>(false)
  const textContainerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

  useEffect(() => setDOMLoaded(true), [])

  // the text size can only be computed *after* the containing <div> is layout and its size known
  const textArangement: string | React.ReactElement = useMemo(() => {
    void isDOMLoaded
    return textContainerRef.current === null ? (
      event.title
    ) : (
      <BestTextArangement
        text={event.title}
        fontFamily={MainFontsNames.fontText}
        maxFontSizeRem={1}
        availableSpace={textContainerRef.current.offsetWidth}
      />
    )
  }, [event.title, isDOMLoaded])

  return (
    <NavigationLink path={{ page: 'event', eventId: event.id }} style={{ gridArea: gridArea, maxHeight: 50, margin: 2 }}>
      <div style={styles.smallEventCard} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {isHovered ? (
          <div style={{ ...styles.eventCardBody, ...styles.eventCardBodyOnHover }}>
            <ResourceImage imageId={event.image} alt="" style={styles.eventVignette} />
            <div ref={textContainerRef} style={{ margin: 6, flexGrow: 1 }}>
              {event.title}
            </div>
          </div>
        ) : (
          <div style={styles.eventCardBody}>
            {!small && <ResourceImage imageId={event.image} alt="" style={styles.eventVignette} />}
            <div ref={textContainerRef} style={{ ...styles.eventCardText, ...(isDOMLoaded ? {} : { whiteSpace: 'nowrap', color: 'transparent' }) }}>
              {textArangement}
            </div>
          </div>
        )}
      </div>
    </NavigationLink>
  )
}

const BestTextArangement: React.FC<{ text: string; fontFamily: string; maxFontSizeRem: number; minFontSizeRem?: number; availableSpace: number }> = ({
  text,
  availableSpace,
  fontFamily,
  maxFontSizeRem,
  minFontSizeRem = 0.4,
}) => {
  if (availableSpace === 0) return <span>{text}</span>
  for (let fontSizeRem = maxFontSizeRem; fontSizeRem > minFontSizeRem; fontSizeRem *= 0.8) {
    const splitText: string[] = TextService.splitTextNicely(text)
    const oneLineLength: number | undefined = TextService.getTextWidth(text, `${fontSizeRem}rem ${fontFamily}`)
    const splitLinesLength: number | undefined = Math.max(
      ...splitText
        .filter((l) => l.length)
        .map((l) => TextService.getTextWidth(l, `${fontSizeRem}rem ${fontFamily}`))
        .filter((l: number | undefined): l is number => l !== undefined)
    )
    if (oneLineLength === undefined) {
      break
    } else if (availableSpace > oneLineLength) {
      return <span style={{ fontSize: `${fontSizeRem}rem` }}>{text}</span>
    } else if (availableSpace > splitLinesLength) {
      return (
        <div style={{ fontSize: `${fontSizeRem}rem` }}>
          {splitText.map((l, i) => (
            <div key={i} style={{ whiteSpace: 'nowrap' }}>
              {l}
            </div>
          ))}
        </div>
      )
    }
  }
  return <div style={{ textAlign: 'center' }}>...</div>
}

const styles = StyleSheet.create({
  gridContainer: {
    display: 'grid',
    minHeight: '20vh',
    overflowX: 'scroll',
    userSelect: 'none',
    position: 'relative',
  },

  titlesStickyPanel: {
    position: 'sticky',
    width: 'auto',
    height: '100%',
    backgroundColor: MainStylesVars.colorBackground,
    left: 0,
    zIndex: 2,
    marginRight: 20,
  },

  rightShadow: {
    position: 'absolute',
    inset: 0,
    boxShadow: 'rgba(0, 0, 0, 0.4) -26px 9px 10px -19px inset',
    pointerEvents: 'none',
  },

  smallEventCard: {
    borderRadius: 10,
    backgroundColor: MainStylesVars.colorBlockBackground,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    boxSizing: 'border-box',
    minWidth: '100%',
  },

  eventCardBody: {
    height: '100%',
    backgroundColor: MainStylesVars.colorBlockBackground,
    flexGrow: 1,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },

  eventCardBodyOnHover: {
    flexShrink: 0,
    zIndex: 1,
    boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.7)',
    minWidth: '100%',
  },

  eventCardText: {
    margin: 6,
    flexGrow: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  eventVignette: {
    height: 'calc(100% - 10px)',
    marginLeft: 5,
    marginRight: 8,
    borderRadius: 6,
    overflow: 'hidden',
    flexShrink: 0,
  },
})
