import React, { useEffect, useRef } from 'react'

type PartialMouseEvent = Pick<MouseEvent, 'pageX' | 'pageY' | 'preventDefault'>
type PartialMouseListener = (e: PartialMouseEvent) => void
type DragContext = {
  startX: number
  startY: number
  scrollLeft: number
  scrollTop: number
  isMouseDown: boolean
  dragStartListener: PartialMouseListener
  moveListener: PartialMouseListener
  dragEndListener: PartialMouseListener
  contextInitialized: boolean
}

export type ScrollStore = { positionX: number; positionY: number }

export const Draggable: React.FC<React.PropsWithChildren<{ style?: React.CSSProperties; scrollSpeed?: number; scrollStore?: ScrollStore }>> = ({
  children,
  style,
  scrollSpeed = 1,
  scrollStore,
}) => {
  // slightly unconventional way of handling refs/closures that allows for 0 react redraws
  const containerRef = useRef<HTMLDivElement>(null)
  const mouseCoords = useRef<DragContext>({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
    isMouseDown: false,
    dragStartListener: () => {},
    moveListener: () => {},
    dragEndListener: () => {},
    contextInitialized: false,
  })

  const isInitial = !mouseCoords.current.contextInitialized
  useEffect(() => {
    // restore previous scroll position on showup
    if (isInitial && scrollStore) containerRef.current?.firstElementChild?.scrollTo(scrollStore.positionX, scrollStore.positionY)
  })

  if (!mouseCoords.current.contextInitialized) {
    mouseCoords.current.dragStartListener = (e: PartialMouseEvent): void => {
      if (!containerRef.current) return
      const slider = containerRef.current.children[0] as HTMLDivElement
      mouseCoords.current.startX = e.pageX - slider.offsetLeft
      mouseCoords.current.startY = e.pageY - slider.offsetTop
      mouseCoords.current.scrollLeft = slider.scrollLeft
      mouseCoords.current.scrollTop = slider.scrollTop
      mouseCoords.current.isMouseDown = true
      window.addEventListener('pointermove', mouseCoords.current.moveListener)
      window.addEventListener('pointerup', mouseCoords.current.dragEndListener)
      document.body.style.cursor = 'grabbing'
      containerRef.current.style.cursor = 'grabbing'
    }
    mouseCoords.current.dragEndListener = (): void => {
      window.removeEventListener('pointermove', mouseCoords.current.moveListener)
      window.removeEventListener('pointerup', mouseCoords.current.dragEndListener)
      mouseCoords.current.isMouseDown = false
      document.body.style.removeProperty('cursor')
      if (containerRef.current) containerRef.current.style.cursor = 'grab'
    }
    mouseCoords.current.moveListener = (e: PartialMouseEvent): void => {
      if (!mouseCoords.current.isMouseDown || !containerRef.current) return
      e.preventDefault()
      const slider = containerRef.current.children[0] as HTMLDivElement
      const x = e.pageX - slider.offsetLeft
      const y = e.pageY - slider.offsetTop
      const walkX = (x - mouseCoords.current.startX) * scrollSpeed
      const walkY = (y - mouseCoords.current.startY) * scrollSpeed
      slider.scrollLeft = mouseCoords.current.scrollLeft - walkX
      slider.scrollTop = mouseCoords.current.scrollTop - walkY
      if (scrollStore) {
        scrollStore.positionX = slider.scrollLeft
        scrollStore.positionY = slider.scrollTop
      }
    }
    mouseCoords.current.contextInitialized = true
  }

  return (
    <div ref={containerRef} onPointerDown={mouseCoords.current.dragStartListener} style={{ ...style, cursor: 'grab' }}>
      {children}
    </div>
  )
}
