import type { DateDay, DateTimeMs, Event, GrabEvent, ProgramGranularity } from '../grab/types'
import { addMinutes, getDateDay, getMonthDay } from './date'
import type { Config } from './grab'
import { filterOutCurrentAndPastEvents, getSortEventsByDateDesc, getSortEventsByWeightDesc } from './sort'

export type EventFilterable = Pick<Event, 'showStartDate' | 'showEndDate'>
export type EventSortable = EventFilterable & Pick<Event, 'weight' | 'title' | 'day'>

export const getNextGroupIndexWithEvents = (groups: DateDay[], eventsByGroups: { [key: DateDay]: any[] }, currentDay: DateDay): number => {
  const groupsWithEvents = Object.entries(eventsByGroups)
    .filter(([, values]) => values.length !== 0)
    .map(([key]) => key as DateDay)

  const groupToShow =
    groupsWithEvents.find((d) => currentDay <= d) ??
    groupsWithEvents[groupsWithEvents.length - 1] ??
    groups.find((d) => currentDay <= d) ??
    groups[groups.length - 1]
  return groups.indexOf(groupToShow)
}

// sort events :
// * by date if alwaysShowHour is true or in delay
// * by weight if alwaysShowHour is false and future event
export const sortEvents = <T extends EventSortable>(
  events: T[],
  alwaysShowHour: boolean,
  delay: number | undefined,
  current: DateTimeMs,
  orderEventsByEndDate: boolean,
  sortByDay: boolean // for view where we want to keep events by day (program, favorites, artist-detail) event if alwaysShowHour is false
): T[] => {
  if (alwaysShowHour) {
    return [...events].sort(getSortEventsByDateDesc(orderEventsByEndDate))
  }
  const limit = addMinutes(current, delay ?? 0)
  return [
    ...events.filter((event) => !filterOutCurrentAndPastEvents(limit)(event)).sort(getSortEventsByDateDesc(orderEventsByEndDate)),
    ...events.filter(filterOutCurrentAndPastEvents(limit)).sort(getSortEventsByWeightDesc(orderEventsByEndDate, sortByDay)),
  ]
}

const groupKey = (mode: ProgramGranularity, date: DateTimeMs, config: Pick<Config, 'timezone'>): DateDay => {
  switch (mode) {
    case 'DAILY':
      return getDateDay(date, { timezone: config.timezone })
    case 'MONTHLY':
      return getMonthDay(date)
  }
}

export const getAllPossibleGroupsWithEvents = (
  events: Pick<GrabEvent, 'showStartDate'>[],
  granularity: ProgramGranularity,
  config: Pick<Config, 'timezone'>
): DateDay[] => {
  return events.reduce<DateDay[]>((acc, e) => {
    const day = groupKey(granularity, e.showStartDate, config)
    if (!acc.includes(day)) {
      acc.push(day)
    }

    return acc
  }, [])
}

export const getEventsByGroups = <T extends GrabEvent>(
  events: T[],
  granularity: ProgramGranularity,
  config: Pick<Config, 'timezone'>
): { [p: DateDay]: T[] } => {
  return events.reduce<{ [key: DateDay]: T[] }>((acc, e) => {
    const key = groupKey(granularity, e.showStartDate, config)
    acc[key] = [...(acc[key] ?? []), e]
    return acc
  }, {})
}
